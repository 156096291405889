import { API, graphqlOperation } from "aws-amplify";
import * as queries from "@/graphql/queries";
import { i18n } from "@/plugins/language";

import {
  COMMIT_CCP_INSTANCE,
  SET_CCP_INSTANCE,
  SET_MIC_CONTROLL,
  SET_MIC_SILENCE,
  SET_MODAL_ACTIVE,
  SET_SHOW_ALERTS_DRAWER,
  SET_SHOW_TEAM_STATUS,
  SET_VOLUMN_CONTROLL,
  SET_VOLUMN_SILENCE,
} from "@/store/actions.type";
import { Modal, Notice } from "view-design";

import Vue from "vue";
import Vuex from "vuex";
import { abilityPlugin } from "./plugins/ability";
import logs from "./modules/logs";

import newDashboard from "./modules/new-dashboard";
import settings from "./modules/settings";
import ui from "./ui";
import authentication from "./modules/authentication";

Vue.use(Vuex);

function setPermissionGroups(model) {
  let permissionGroup = [];

  if (!model) return permissionGroup;

  for (let x = 0; x < model.length; x++) {
    let securityGroup = model[x];

    if (securityGroup.PermissionItems) {
      for (let x = 0; x < securityGroup.PermissionItems.length; x++) {
        let securityItem = securityGroup.PermissionItems[x];
        Object.keys(securityItem.Values).forEach((key) => {
          let value = securityItem.Values[key];
          if (value) {
            permissionGroup.push({
              actions: key.toLocaleLowerCase(),
              subject: securityItem.Action,
            });
          }
        });
      }
    }
  }

  return permissionGroup;
}

function getMyPermissions(model) {
  if (!model) return [];
  return setPermissionGroups(model.PermissionGroups);
}

import createPersistedState from "vuex-persistedstate";

const persistedstate = createPersistedState({
  paths: [
    "authentication",
    "hid",
    "logs",
    "currentUser",
    "settings",
    "health",
    "contacts",
    "team",
    "chat",
    "queue",
    "activity",
    "api",
    "agent",
    "holiday",
    "securityProfile",
    "usage",
    "mediaDevices",
    "announcement",
    "initialized",
    "rules",
    "staticNumbers",
    "wWidth",
    "role",
    "isAuthenticating",
    "isConnected",
    "isFetchingAuthenticating",
    "ccpInstanceId",
    "isDissmissTestCall",
    "isLoading",
    "isModalActive",
    "isVolumnSilent",
    "isNitroMode",
    "isVolumnControllShow",
    "isMicControllShow",
    "isMicSilent",
    "isShowTeamStatus",
    "isShowAlertsDrawer",
    "hasAlerts",
    "enableBtnAws",
    "lockDashboardDrag",
    "soundVolume",
    "globalCallDuration",
    "tableConfig",
    "support",
    "translations",
    "surveys",
    "selfService",
    "users",
  ],
});

const state = new Vuex.Store({
  // plugins: [abilityPlugin, logrocketPlugin],
  plugins: [abilityPlugin, persistedstate],
  state: {
    staticNumbers: {
      "us-east-1": "+18042221111",
      "us-west-2": "+18042221111",
      "eu-central-1": "+448000154245",
      "ap-southeast-2": "+61291920995",
      "ap-northeast-1": "+81332249999",
    },
    appsyncSubscriptions: [],
    failedSubscriptions: [],
    webRTCDetection: {
      hasMicrophone: false,
      hasSpeakers: false,
      hasWebcam: false,
      isMicrophoneAlreadyCaptured: false,
      isWebcamAlreadyCaptured: false,
      MediaDevices: [],
      isHTTPs: location.protocol === "https:",
      canEnumerate: false,
    },
    wWidth: window.innerWidth,
    wHeight: window.innerHeight,
    role: {
      isSupervisor: false,
      isAgent: false,
    },
    initialized: false,
    isAuthenticating: false,
    isConnected: false,
    isFetchingAuthenticating: false,
    // ccpInstanceId: "awscallcenterlesson2",
    ccpInstanceId: null,
    isDissmissTestCall: false,
    isLoading: false,
    isModalActive: false,
    isVolumnSilent: false,
    isNitroMode: false,
    isVolumnControllShow: false,
    isMicControllShow: false,
    isMicSilent: false,
    isShowTeamStatus: false,
    isShowAlertsDrawer: false,
    hasAlerts: false,
    rules: [],
    enableBtnAws: false,
    lockDashboardDrag: true,
    soundVolume: 50,
    api: {},
    globalCallDuration: 0,
    dashboardCards: {
      colOne: [
        {
          id: 5,
          name: "Contacts In Queue",
          action: "contacts_in_queue",
          component: "contacts-in-queue",
          isShow: true,
        },
        {
          id: 7,
          name: "Contacts",
          action: "contacts",
          component: "contact-metrics",
          isShow: true,
        },
      ],
      colTwo: [
        {
          id: 2,
          name: "My Recent Activity",
          action: "my_recent_activity",
          component: "my-recent-activity",
          isShow: true,
        },
        {
          id: 1,
          name: "My Performance",
          action: "my_performance",
          component: "my-performance",
          isShow: true,
        },
      ],
      colThree: [
        {
          id: 3,
          name: "Agent Availabilty",
          action: "agent_availability",
          component: "agent-availability",
          isShow: true,
        },
        {
          id: 6,
          name: "Handling Time",
          action: "handling_time",
          component: "handling-time",
          isShow: true,
        },
        {
          id: 4,
          name: "Wait Time",
          action: "wait_time",
          component: "wait-time",
          isShow: true,
        },
      ],
    },
    showChangeAgentStatusDialog: false,
    selectedAgentForStatusChange: null,
    mobileView: false,
    externalContactLeft: false,
  },

  modules: {
    newDashboard,
    logs,
    settings,
    ui,
    authentication,
  },

  getters: {
    externalContactLeft: (state) => state.externalContactLeft,
    role: (state) => state.role,
    isSupervisor: (state) => state.role.isSupervisor,
    isLoading: (state) => state.isLoading,
    ccpInstanceId: (state) => state.ccpInstanceId,
    initialized: (state) => state.initialized,
    isNitroMode: (state) => state.isNitroMode,
    winWidth: (state) => state.wWidth,
    winHeight: (state) => state.wHeight,
    webRTCDetection: (state) => state.webRTCDetection,
    globalCallDuration: (state) => state.globalCallDuration,
    isDissmissTestCall: (state) => state.isDissmissTestCall,
    staticNumbersPerRegion: (state) => state.staticNumbers,
  },

  actions: {
    async handleFailedSubscriptions(context) {
      let needToRefetchQueueMetrics = false;

      // check to make sure this isn't a refresh
      if (!window.ignoreResubscribe) {
        //for each failed subscription try to resubscribe
        for (let i = 0; i < context.state.failedSubscriptions.length; i++) {
          try {
            var v = context.state.failedSubscriptions[i];
            context.dispatch(
              "logs/addItem",
              {
                component: "APPSYNC",
                level: "DEBUG",
                text: `Re-subscribing - ${v.id}`,
              },
              { root: 1 }
            );
            v.sub = v.observable.subscribe(v.handler);

            context.dispatch(
              "logs/addItem",
              {
                component: "APPSYNC",
                level: "INFO",
                text: `Successfully resubscribed - ${v.id}`,
              },
              { root: 1 }
            );

            if (v.linkedQuery) {
              if (v.linkedQuery.name === "queue/onFetchQueue") {
                needToRefetchQueueMetrics = true;
              } else {
                await context.dispatch(v.linkedQuery.name, v.linkedQuery.params);
              }
            }

            context.state.failedSubscriptions.splice(i, 1);
          } catch (err) {
            context.dispatch(
              "logs/addItem",
              {
                component: "APPSYNC",
                level: "WARN",
                text: `Failed to re-subscribe - ${v.id}`,
              },
              { root: 1 }
            );
          }
        }
      }

      if (needToRefetchQueueMetrics) {
        let startDate = new Date();
        startDate.setHours(0);
        startDate.setMinutes(0);

        this.$store.commit("newDashboard/setStartDate", startDate);
        this.$store.commit("newDashboard/setEndDate", new Date());
        try {
          let { InstanceId, Queues } = state.getters ? state.getters.currentUser : {};
          await context.dispatch("queue/onFetchQueue", {
            InstanceId,
            Queues,
            DateRange: this.$store.getters["newDashboard/dateRange"],
          });
        } catch (err) {
          console.error("error re-fetching queue metrics: ", err);
        }
      }
    },
    resubscribeAll(context) {
      context.state.appsyncSubscriptions.forEach((session) => {
        session.sub.unsubscribe();
      });

      context.state.appsyncSubscriptions.forEach((session) => {
        if (session.sub["_state"] !== "ready") {
          session.observable.subscribe(session.handler);
        }
      });
    },
    unsubscribeAll(context) {
      context.state.appsyncSubscriptions.forEach((session) => {
        session.sub.unsubscribe();
      });
    },
    [SET_CCP_INSTANCE](context, ccpInstanceId) {
      // console.log(ccpInstanceId);
      context.commit(COMMIT_CCP_INSTANCE, ccpInstanceId);
    },

    INITIALIZE_RULES({ commit }, { agent }) {
      // TODO
      // agent.Roles.indexOf("instance-admins") > -1
      // agent.Username.includes("IAM@")

      if (agent && Object.prototype.hasOwnProperty.call(agent, "SecurityProfile")) {
        let userPermisisons = getMyPermissions(agent.SecurityProfile);
        commit("CREATE_SESSION_RULES", userPermisisons);
      }
    },
    SET_CURRENT_USER_RULES(context, payload) {
      // console.log("TARGET LOCK", payload);

      if (payload && Object.prototype.hasOwnProperty.call(payload, "PermissionGroups")) {
        let userPermisisons = getMyPermissions(payload);
        context.commit("CREATE_SESSION_RULES", userPermisisons);
      }
    },
    UPDATE_RULES({ commit }, { profile }) {
      if (profile && Object.prototype.hasOwnProperty.call(profile, "SecurityProfile")) {
        const userPermisisons = getMyPermissions(profile.SecurityProfile);
        commit("CREATE_SESSION_RULES", userPermisisons);
      } else {
        const userPermisisons = getMyPermissions(profile);
        commit("CREATE_SESSION_RULES", userPermisisons);
      }
    },
    verifyCredentials(context, payload) {
      return new Promise((resolve) => {
        API.graphql(graphqlOperation(queries.verifyCreds, payload)).then((res) => {
          context.commit("setNitroMode", true);
          resolve(res.data);
        });
      });
    },
    micCheck(context) {
      let _ctx = context;
      Notice.warning({
        name: "mic-permisision",
        title: i18n.t("notifications.pleaseAllowAccessToMicrophone"),
        duration: 0,
        desc: i18n.t("notifications.theDescWillHideWhenYouSetRender"),
        render: (h) => {
          return h("span", [
            i18n.t("notifications.unableToMakeOrReceiveCallsClick") + " ",
            h(
              "a",
              {
                on: {
                  click: () => {
                    navigator.mediaDevices
                      .getUserMedia({ audio: true })
                      .then(function () {
                        // console.log("You let me use your mic!", stream);
                        _ctx.state.webRTCDetection.hasMicrophone = true;
                        Notice.close("mic-permisision");
                      })
                      .catch(function () {
                        _ctx.state.webRTCDetection.hasMicrophone = false;
                        Modal.info({
                          title: i18n.t("modal.changeSitesMicrophonePermissions"),
                          onOk: () => {
                            Notice.close("mic-permisision");
                          },
                          render: (h) => {
                            return h("div", [
                              h("p", `1. ${i18n.t("modal.openChrome")}`),
                              h("p", [
                                `2. ${i18n.t("modal.atTheTopRightClickMore")} `,
                                h("i", {
                                  class: ["fa fa-ellipsis-v"],
                                }),
                                ` > ${i18n.t("modal.settings")}.`,
                              ]),
                              h("p", [`3. ${i18n.t("modal.atTheBottomClick")} `, h("b", `${i18n.t("modal.advanced")} `)]),
                              h("p", [
                                `4. ${i18n.t("modal.underPrivacyAndSecurityClick")} `,
                                h("b", `${i18n.t("modal.contentSettings")}.`),
                              ]),
                              h("p", [`5. ${i18n.t("modal.click")}`, h("b", ` ${i18n.t("modal.microphone")}.`)]),
                              h("p", [
                                `6. ${i18n.t("modal.turn")}`,
                                h("b", ` ${i18n.t("modal.askBeforeAccessing")}`),
                                ` ${i18n.t("modal.onOrOff")}.`,
                              ]),
                            ]);
                          },
                        });
                        Notice.close("mic-permisision");
                      });
                  },
                },
              },
              i18n.t("notifications.here")
            ),
          ]);
        },
      });
    },
    handleStoragePutError(context, error) {
      //RequestTimeTooSkewed
      if (error.code === "RequestTimeTooSkewed") {
        Notice.error({
          name: "s3-upload-time-skewed",
          title: i18n.t("notifications.uploadFailed"),
          duration: 0,
          render: (h) => {
            return [
              h("p", `${i18n.t("modal.itLooksLikeSomethingsWrongWithTheTimeInYourMachine")}.`),
              h("br"),
              h("p", `${i18n.t("modal.pleaseCheckTheTimeIfItsCorrect")}.`),
              h("br"),
              h("p", `${i18n.t("modal.ifYourMachineTimeIsCorrectMaybeTheTimezoneWasNotSetCorrectly")}.`),
            ];
          },
        });
      }
    },
    detectWebRTC(context) {
      if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        navigator.enumerateDevices = function (callback) {
          navigator.mediaDevices.enumerateDevices().then(callback);
        };
      }
      if (typeof MediaStreamTrack !== "undefined" && "getSources" in MediaStreamTrack) {
        context.state.webRTCDetection.canEnumerate = true;
      } else if (navigator.mediaDevices && !!navigator.mediaDevices.enumerateDevices) {
        context.state.webRTCDetection.canEnumerate = true;
      }
      checkDeviceSupport();
    },
    updateAuthentication({ commit }, payload) {
      commit("SET_FETCHING_AUTHENTICATION", payload);
    },
  },

  mutations: {
    markExternalContactLeft(state) {
      state.externalContactLeft = true;
    },
    unMarkExternalContact(state) {
      state.externalContactLeft = false;
    },
    addSubscriptionToList(state, v) {
      if (v.id.endsWith("undefined")) {
        return;
      }
      let index = state.appsyncSubscriptions.findIndex((s) => s.id === v.id);
      if (index === -1) {
        v.sub = v.observable.subscribe(v.handler);
        v.tries = 0;
        state.appsyncSubscriptions.push(v);
      }
    },
    removeSubscriptionFromList(state, { id }) {
      let index = state.appsyncSubscriptions.findIndex((s) => s.id === id);
      if (index > -1) {
        state.appsyncSubscriptions[index].sub.unsubscribe();
        state.appsyncSubscriptions.splice(index, 1);
      }
    },
    onDissmissTestCall(state, v) {
      state.isDissmissTestCall = v;
    },
    setGlobalCallDuration(state, v) {
      state.globalCallDuration = v;
    },
    setWindowSize(state, v) {
      state.wWidth = v.innerWidth;
      state.wHeight = v.innerHeight;
      state.mobileView = v < 1024 ? true : false;
    },
    SET_AUTH_AWS(state, v) {
      state.enableBtnAws = v;
    },
    setNitroMode(state, v) {
      state.isNitroMode = v;
    },
    SET_CCP_INSTANCE_ALIAS(state, v) {
      state.ccpInstanceId = v;
    },

    SET_VOLUME(state, v) {
      state.soundVolume = v;
    },

    SET_DRAGGABLE_LOCK(state, v) {
      state.lockDashboardDrag = v;
    },

    CREATE_SESSION_RULES(state, v) {
      state.rules = v;
    },

    SET_HYBERNATE(state, v) {
      state.isAuthenticating = v;
    },

    SET_FETCHING_AUTHENTICATION(state, v) {
      state.isFetchingAuthenticating = v;
    },

    [SET_SHOW_ALERTS_DRAWER](state, v) {
      state.isShowAlertsDrawer = v;
    },

    [SET_SHOW_TEAM_STATUS](state, v) {
      state.isShowTeamStatus = v;
    },

    [SET_VOLUMN_SILENCE](state, v) {
      state.isVolumnSilent = v;
    },

    [SET_VOLUMN_CONTROLL](state, v) {
      state.isVolumnControllShow = v;
    },

    [SET_MIC_CONTROLL](state, v) {
      state.isMicControllShow = v;
    },

    [SET_MIC_SILENCE](state, v) {
      state.isMicSilent = v;
    },

    [SET_MODAL_ACTIVE](state, v) {
      state.isModalActive = v;
    },

    [COMMIT_CCP_INSTANCE](state, ccpInstanceId) {
      // console.log(ccpInstanceId);
      state.ccpInstanceId = ccpInstanceId;
    },

    SET_INITIALIZED(state, value) {
      state.initialized = value;
    },

    initState(state) {
      state.staticNumbers = {
        "us-east-1": "+18042221111",
        "us-west-2": "+18042221111",
        "eu-central-1": "+448000154245",
        "ap-southeast-2": "+61291920995",
        "ap-northeast-1": "+81332249999",
      };
      state.webRTCDetection = {
        hasMicrophone: false,
        hasSpeakers: false,
        hasWebcam: false,
        isMicrophoneAlreadyCaptured: false,
        isWebcamAlreadyCaptured: false,
        MediaDevices: [],
        isHTTPs: location.protocol === "https:",
        canEnumerate: false,
      };
      state.wWidth = window.innerWidth;
      state.role = {
        isSupervisor: false,
        isAgent: false,
      };
      state.initialized = false;
      state.isAuthenticating = false;
      state.isConnected = false;
      state.isFetchingAuthenticating = false;
      state.ccpInstanceId = null;
      state.isDissmissTestCall = false;
      state.isLoading = false;
      state.isModalActive = false;
      state.isVolumnSilent = false;
      state.isNitroMode = false;
      state.isVolumnControllShow = false;
      state.isMicControllShow = false;
      state.isMicSilent = false;
      state.isShowTeamStatus = false;
      state.isShowAlertsDrawer = false;
      state.hasAlerts = false;
      state.rules = [];
      state.enableBtnAws = false;
      state.lockDashboardDrag = true;
      state.soundVolume = 50;
      state.api = {};
      state.globalCallDuration = 0;
      state.dashboardCards = {
        colOne: [
          {
            id: 5,
            name: "Contacts In Queue",
            action: "contacts_in_queue",
            component: "contacts-in-queue",
            isShow: true,
          },
          {
            id: 7,
            name: "Contacts",
            action: "contacts",
            component: "contact-metrics",
            isShow: true,
          },
        ],
        colTwo: [
          {
            id: 2,
            name: "My Recent Activity",
            action: "my_recent_activity",
            component: "my-recent-activity",
            isShow: true,
          },
          {
            id: 1,
            name: "My Performance",
            action: "my_performance",
            component: "my-performance",
            isShow: true,
          },
        ],
        colThree: [
          {
            id: 3,
            name: "Agent Availabilty",
            action: "agent_availability",
            component: "agent-availability",
            isShow: true,
          },
          {
            id: 6,
            name: "Handling Time",
            action: "handling_time",
            component: "handling-time",
            isShow: true,
          },
          {
            id: 4,
            name: "Wait Time",
            action: "wait_time",
            component: "wait-time",
            isShow: true,
          },
        ],
      };
    },

    updateState(state, value) {
      state.staticNumbers = value.staticNumbers;
      state.wWidth = value.wWidth;
      state.role = value.role;
      state.initialized = value.initialized;
      state.isAuthenticating = value.isAuthenticating;
      state.isConnected = value.isConnected;
      state.isFetchingAuthenticating = value.isFetchingAuthenticating;
      state.ccpInstanceId = value.ccpInstanceId;
      state.isDissmissTestCall = value.isDissmissTestCall;
      state.isLoading = value.isLoading;
      state.isModalActive = value.isModalActive;
      state.isVolumnSilent = value.isVolumnSilent;
      state.isNitroMode = value.isNitroMode;
      state.isVolumnControllShow = value.isVolumnControllShow;
      state.isMicControllShow = value.isMicControllShow;
      state.isMicSilent = value.isMicSilent;
      state.isShowTeamStatus = value.isShowTeamStatus;
      state.isShowAlertsDrawer = value.isShowAlertsDrawer;
      state.hasAlerts = value.hasAlerts;
      state.rules = value.rules;
      state.enableBtnAws = value.enableBtnAws;
      state.lockDashboardDrag = value.lockDashboardDrag;
      state.soundVolume = value.soundVolume;
      state.api = value.api;
      state.globalCallDuration = value.globalCallDuration;
      state.dashboardCards = value.dashboardCards;
    },
    setShowChangeAgentStatusDialog(state, v) {
      state.showChangeAgentStatusDialog = v;
    },
    setSelectedAgentForStatusChange(state, v) {
      state.selectedAgentForStatusChange = v;
    },
  },
});

let checkDeviceSupport = function (callback) {
  if (!state.state.webRTCDetection.canEnumerate) return;
  if (!navigator.enumerateDevices && window.MediaStreamTrack && window.MediaStreamTrack.getSources)
    navigator.enumerateDevices = window.MediaStreamTrack.getSources.bind(window.MediaStreamTrack);

  if (!navigator.enumerateDevices && navigator.enumerateDevices)
    navigator.enumerateDevices = navigator.enumerateDevices.bind(navigator);

  if (!navigator.enumerateDevices) {
    if (callback) callback();
    return;
  }

  state.state.webRTCDetection.MediaDevices = [];
  navigator.enumerateDevices(function (devices) {
    devices.forEach(function (_device) {
      var device = {};
      for (var d in _device) {
        device[d] = _device[d];
      }

      if (device.kind === "audio") device.kind = "audioinput";

      if (device.kind === "video") device.kind = "videoinput";

      var skip;
      state.state.webRTCDetection.MediaDevices.forEach(function (d) {
        if (d.id === device.id && d.kind === device.kind) skip = true;
      });

      if (skip) return;

      if (!device.deviceId) device.deviceId = device.id;

      if (!device.id) device.id = device.deviceId;

      if (!device.label) {
        // device.label = "Please invoke getUserMedia once.";
        // if (!state.state.webRTCDetection.isHTTPs)
        //   device.label =
        //     "HTTPs is required to get label of this " +
        //     device.kind +
        //     " device.";
      } else {
        if (device.kind === "videoinput" && !state.state.webRTCDetection.isWebcamAlreadyCaptured) {
          state.state.webRTCDetection.isWebcamAlreadyCaptured = true;
        }
        if (device.kind === "audioinput" && !state.state.webRTCDetection.isMicrophoneAlreadyCaptured) {
          state.state.webRTCDetection.isMicrophoneAlreadyCaptured = true;
        }
      }

      if (device.kind === "audioinput" && device.label) {
        state.state.webRTCDetection.hasMicrophone = true;
      }

      if (device.kind === "audiooutput") state.state.webRTCDetection.hasSpeakers = true;

      if (device.kind === "videoinput") state.state.webRTCDetection.hasWebcam = true;

      state.state.webRTCDetection.MediaDevices.push(device);
    });

    if (callback) {
      callback();
    }
  });
};

setInterval(() => {
  let subs = state.state.appsyncSubscriptions;

  let closedSubs = checkForClosedSubs(subs);

  if (closedSubs.length > 0) {
    state.state.failedSubscriptions = closedSubs;
    state.dispatch("handleFailedSubscriptions");
  }
}, 2000);

export default state;

function checkForClosedSubs(subs) {
  if (subs.length > 0) {
    let closedSubs = subs.filter((sub) => {
      return sub.sub["_state"] !== "ready";
    });
    return closedSubs;
  }
  return [];
}
