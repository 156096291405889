import VueCookie from "vue-cookie";
import { NEW_STATE, SET_CONNECTED } from "@/store/actions.type";

import Socket from "@/common/socket.js";

import { Auth } from "aws-amplify";
import AmplifyHandler from "@/plugins/amplify";
import { fetcherAmplify } from "@/libs/fetcher";

import { heartbeat } from "@/graphql/mutations";

const amplify = new AmplifyHandler();

const state = {
  isAutenticatedInAmplify: false,
  ccpUrl: null,
};

const getters = {
  isAutenticatedInAmplify: (state) => state.isAutenticatedInAmplify,
  ccpUrl: (state) => state.ccpUrl,
};

const mutations = {
  setIsAutenticatedInAmplify(state, value) {
    state.isAutenticatedInAmplify = value;
  },
  setCcpUrl(state, url) {
    state.ccpUrl = url;
  },
};

const actions = {
  async setSession({ dispatch, getters }) {
    if (!getters.ccpUrl) dispatch("setCcpUrl");
    await dispatch("setIsAutenticatedInAmplify");
  },

  async setIsAutenticatedInAmplify({ commit }) {
    try {
      await amplify.configure(window.localStorage.getItem("connect-region"));
      const response = await amplify.isAutenticated();
      commit("setIsAutenticatedInAmplify", response);
    } catch (error) {
      console.error(error);
      commit("setIsAutenticatedInAmplify", null);
    }
  },

  setCcpUrl({ commit }) {
    commit("setCcpUrl", window.localStorage.getItem("ccpUrl") || null);
  },

  async logOut({ rootGetters, dispatch, commit, getters }) {
    const offline = rootGetters.agentStates.filter((state) => {
      return state.type === "offline";
    });
    dispatch(NEW_STATE, offline[0], { root: 1 });
    window.isLoggingOut = true;
    dispatch(
      "logs/addItem",
      {
        component: "DEXTR",
        level: "INFO",
        text: `LOGOUT - ${rootGetters.agentSummary.Username} - ${rootGetters.agentSummary.InstanceId}`,
      },
      { root: true }
    );

    Socket.close();
    const ccpUrl = window.localStorage.getItem("ccpUrl") || getters.ccpUrl;
    let logoutWindow = window.open(`https://${ccpUrl}/connect/logout`, "_blank");

    dispatch("updateAuthentication", false, { root: true });
    commit(SET_CONNECTED, false, {
      root: true,
    });

    try {
      await fetcherAmplify().graphql({
        query: heartbeat,
        variables: {
          input: {
            InstanceId: rootGetters.agentSummary.InstanceId,
            Username: rootGetters.agentSummary.Username,
            StatusName: "Offline",
            StatusDuration: 1000,
          },
        },
      });

      Auth.configure(Auth._config);
      await Auth.signOut({ global: true });

      if (window.intervalUpdateUser) {
        clearInterval(window.intervalUpdateUser);
        window.intervalUpdateUser = null;
      }

      this.signoutTimeout = setTimeout(() => {
        commit("SET_HYBERNATE", false);
        let alias = window.localStorage.getItem("ccpInstanceAlias");
        let region = window.localStorage.getItem("connect-region");
        let defaultCountry = window.localStorage.getItem("defaultCountry");
        let showNativeCcp = window.localStorage.getItem("showNativeCcp");
        let language = window.localStorage.getItem("language");

        window.localStorage.clear();
        window.localStorage.setItem("connect-region", region);
        window.localStorage.setItem("ccpInstanceAlias", alias);
        window.localStorage.setItem("defaultCountry", defaultCountry);
        window.localStorage.setItem("showNativeCcp", showNativeCcp);
        window.localStorage.setItem("language", language);

        if (!window.location.origin) {
          window.location.origin = `${window.location.protocol}//${window.location.hostname}${
            window.location.port ? `:${window.location.port}` : ""
          }`;
        }

        if (VueCookie.get("dextr_subdomain")) {
          window.location.href = `${window.location.origin}/${VueCookie.get("dextr_subdomain")}`;
          return false;
        }

        window.location.href = window.location.origin;
        logoutWindow.close();
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
