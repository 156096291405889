import { existPartnerTemplate } from "../common/partners-templates";
import { Notice } from "view-design";
import { i18n } from "@/plugins/language";

const state = {
  isSidebarShown: true,
  isFullScreen: false,
  agentPerformanceExpand: false,
  partnerTemplate: null,
  list: [
    {
      name: "first-section",
      topDivision: false,
      key: "links",
      child: [
        {
          label: i18n.t("tooltip.home"),
          name: "dashboard",
          route: "/dashboard",
          icon: "fa-home",
          tooltip: "Home",
          labelTranslated: "tooltip.home",
          id: "childDashboard",
        },
        {
          label: i18n.t("tooltip.queues"),
          name: "queues",
          route: "/queues",
          icon: "fa-home",
          tooltip: "Queues",
          labelTranslated: "tooltip.queues",
          id: "childQueues",
        },
        {
          label: i18n.t("tooltip.customerEngagement"),
          name: "engage",
          route: "/engage",
          icon: "fa-user-headset",
          tooltip: "",
          labelTranslated: "tooltip.customerEngagement",
          id: "childEngage",
        },
        {
          label: i18n.t("tooltip.activitySearch"),
          name: "activity",
          route: "/activity",
          icon: "fa-search",
          tooltip: "",
          labelTranslated: "tooltip.activitySearch",
          id: "childActivity",
        },
        {
          label: i18n.t("tooltip.newActivitySearch"),
          name: "activityNew",
          route: "/activitynew",
          icon: "fa-search",
          tooltip: "",
          labelTranslated: "tooltip.newActivitySearch",
          id: "newChildActivity",
        },
        {
          label: i18n.t("tooltip.historicalReporting"),
          name: "reports",
          route: "/reports",
          icon: "fa-chart-bar",
          tooltip: "",
          labelTranslated: "tooltip.historicalReporting",
          id: "childReports",
        },
        {
          label: i18n.t("tooltip.directory"),
          name: "contacts",
          route: "/contacts",
          icon: "fa-address-book",
          tooltip: "",
          labelTranslated: "tooltip.directory",
          id: "childDirectory",
        },
        {
          label: i18n.t("tooltip.userManagement"),
          name: "users",
          route: "/users",
          icon: "fa-users",
          tooltip: "",
          labelTranslated: "tooltip.userManagement",
          id: "childUserManagement",
        },
        {
          label: i18n.t("tooltip.support"),
          name: "support",
          visible: false,
          route: "/support",
          icon: "fa-wrench",
          tooltip: "",
          labelTranslated: "tooltip.support",
          id: "childSupport",
        },
      ],
    },
    {
      name: "second-section",
      topDivision: false,
      key: "analytics",
      lastItem: true,
      child: [
        {
          label: i18n.t("settings.customLinks.title"),
          name: "link-solid",
          visible: true,
          route: "",
          icon: "fa-link",
          tooltip: "",
          dropdown: true,
          dropdownTitle: i18n.t("settings.customLinks.title"),
          dropdownLinks: [],
          id: "externalLink",
        },
        {
          label: "Connect",
          name: "connect",
          visible: true,
          route: "",
          icon: "",
          tooltip: "",
          dropdown: true,
          dropdownTitle: i18n.t("settings.connect.analytics"),
          dropdownLinks: [
            {
              label: i18n.t("settings.connect.realTimeMetrics"),
              url: "/real-time-metrics/landing",
            },
            {
              label: i18n.t("settings.connect.historicalMetrics"),
              url: "/historical-metrics/landing",
            },
            {
              label: i18n.t("settings.connect.contactSearch"),
              url: "/contact-search",
            },
            {
              label: i18n.t("settings.connect.loginLogoutReport"),
              url: "/login-logout-report",
            },
            {
              label: i18n.t("settings.connect.savedReports"),
              url: "/metrics-reports",
            },
          ],
          id: "childConnect",
        },
        {
          label: "Settings",
          name: "settings",
          visible: true,
          route: "/settings",
          icon: "fa-wrench",
          tooltip: "",
          id: "childSettings",
        },
      ],
    },
  ],
  isLoadingVisible: false,
};

const getters = {
  showSideBar: (state) => state.isSidebarShown,
  agentPerformanceExpand: (state) => state.agentPerformanceExpand,
  isFullScreen: (state) => state.isFullScreen,
  getMenuList: (state) => state.list,
  getPartnerTemplate: (state) => state.partnerTemplate,
  isLoadingVisible: (state) => state.isLoadingVisible,
};

const mutations = {
  setSidebarShown(state, val) {
    state.isSidebarShown = val;
  },
  setFullScreen(state, payload) {
    state.isFullScreen = payload;
  },
  setAgentPerformanceExpand(state) {
    state.agentPerformanceExpand = !state.agentPerformanceExpand;
  },
  initUI(state) {
    const template = existPartnerTemplate();
    if (template) {
      state.partnerTemplate = {};
      state.partnerTemplate = template;
    } else {
      state.partnerTemplate = null;
    }
    state.isSidebarShown = true;
    state.isFullScreen = false;
    state.agentPerformanceExpand = false;
  },
  updateState(state, value) {
    if (!state.partnerTemplate) {
      state.partnerTemplate = value.partnerTemplate;
    }
    state.isSidebarShown = value.isSidebarShown;
    state.isFullScreen = value.isFullScreen;
    state.agentPerformanceExpand = value.agentPerformanceExpand;
  },
  updateLoadingVisible(state, value) {
    state.isLoadingVisible = value;
  },
  updateCustomLinksOfMenu(state, value) {
    state.list.forEach((element) => {
      if (element.key === "analytics") {
        element.child[0].dropdownLinks = value;
      }
    });
  },
};

const actions = {
  messageEndpointSuccess(context, { message }) {
    Notice.destroy();
    Notice.success({
      title: message,
      duration: 5,
    });
  },
  messageEndpointError(context, { message }) {
    Notice.destroy();
    Notice.error({
      title: message,
      duration: 5,
    });
  },
  setLoadingVisible({ commit }, payload) {
    commit("updateLoadingVisible", payload);
  },
  addCustomLinksToMenuBar({ commit }, payload) {
    const filterByUrl = payload.map((item) => {
      return {
        label: item.name,
        browserTab: item.browserTab,
        url: item.url,
      };
    });
    commit("updateCustomLinksOfMenu", filterByUrl);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
